import React, {useState} from "react";
import {datetime_format, Trip, useDeleteTrip, useUpdateTrip} from "../../api";
import Box from "@mui/material/Box";
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import {createUseStyles} from "react-jss";
import {useNavigate} from "react-router-dom";
import {TripViewCard} from "./TripViewCard";

const style = createUseStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
    }
});

interface TripDetailsEditorProps {
    trip: Trip;
    on_needs_refresh(): void;
}

export function TripDetailsEditor(props: TripDetailsEditorProps) {
    const classes = style();
    const navigate = useNavigate();
    const update_trip = useUpdateTrip()[1];
    const delete_trip = useDeleteTrip();
    const [name, set_name] = useState(props.trip.name);
    const [description, set_description] = useState(props.trip.description);
    const [start_date, set_start_date] = useState<Dayjs | null>(dayjs(props.trip.start_date, datetime_format));
    const [end_date, set_end_date] = useState<Dayjs | null>(dayjs(props.trip.end_date, datetime_format));
    const [is_delete_confirm_open, set_delete_confirm_open] = useState(false);
    const [is_deleting, set_is_deleting] = useState(false);

    const is_dirty = name !== props.trip.name || description !== props.trip.description ||
        start_date?.format(datetime_format) !== props.trip.start_date || end_date?.format(datetime_format) !== props.trip.end_date;
    const is_valid = name.length > 0 && description.length > 0 && start_date !== null && end_date !== null;

    const on_update_trip = () => {
        if (is_valid) {
            update_trip({
                id: props.trip.id,
                name: name,
                description: description,
                start_date: start_date.format(datetime_format),
                end_date: end_date.format(datetime_format),
                fuel_weight: props.trip.fuel_weight,
                water_weight: props.trip.water_weight,
            }, (response) => {
                props.on_needs_refresh();
            });
        }
    }

    return <TripViewCard flexGrow={1} minWidth={100}>
        <div className={classes.form}>
            <Box padding={1}>
                <TextField required
                           margin="dense"
                           id="name"
                           label="Trip Name"
                           fullWidth
                           variant="standard"
                           value={name}
                           onChange={(e) => set_name(e.target.value)}/>
            </Box>
            <Box padding={1}>
                <TextField required
                           multiline
                           margin="dense"
                           id="description"
                           label="descritpion"
                           fullWidth
                           variant="standard"
                           value={description}
                           onChange={(e) => set_description(e.target.value)}/>
            </Box>
            <Box padding={1}>
                <DatePicker label="Start Date"
                            value={start_date}
                            onChange={(new_start_date) => set_start_date(new_start_date)}/>
            </Box>
            <Box padding={1}>
                <DatePicker label="End Date"
                            value={end_date}
                            onChange={(new_end_date) => set_end_date(new_end_date)}/>
            </Box>
            <Box padding={1}>
                <Button disabled={!is_dirty}
                        fullWidth
                        variant="contained"
                        onClick={() => on_update_trip()}>
                    Update
                </Button>
            </Box>
            <Box padding={1}>
                <Button variant="outlined"
                        fullWidth
                        color="warning"
                        onClick={() => {
                            set_delete_confirm_open(true)
                        }}>
                    Delete Trip
                </Button>
            </Box>
        </div>

        <Dialog open={is_delete_confirm_open}
                onClose={() => set_delete_confirm_open(false)}>
            <DialogTitle>Delete trip {props.trip.name}?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this trip? This action cannot be undone!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => set_delete_confirm_open(false)}
                        disabled={is_deleting}>No</Button>
                {!is_deleting &&
                    <Button color="warning"
                            onClick={() => {
                                set_is_deleting(true);
                                delete_trip(props.trip.id, () => {
                                    set_delete_confirm_open(false);
                                    navigate('/trips');
                                });
                            }}>
                        Yes, Delete
                    </Button>
                }
                {is_deleting &&
                    <Button color="warning"
                            disabled
                            endIcon={<CircularProgress size={20}/>}>
                        Yes, Delete
                    </Button>
                }
            </DialogActions>
        </Dialog>
    </TripViewCard>
}