import React from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Trip, useGetTrips} from "../../api";
import {Container, List, ListItem, ListItemButton, ListItemText, Paper, Typography} from "@mui/material";
import {CreateTripModal} from "./CreateTripModal";
import dayjs from "dayjs";

export function TripList() {
    const navigate = useNavigate();
    const [trip_list, refresh_trips] = useGetTrips();

    const select_trip = (trip: Trip) => {
        navigate('/trips/' + trip.id);
    }

    return <div>
        <Container maxWidth="lg">
            <Paper elevation={3}>
                <Box padding={4}>
                    <Typography variant="h4">Trip List</Typography>
                    <List>
                        {trip_list && trip_list.map((trip, i) => (
                            <ListItem key={i}
                                      onClick={() => select_trip(trip)}>
                                <ListItemButton>
                                    <ListItemText primary={trip.name} secondary={
                                        <div>
                                            <Typography sx={{display: 'inline'}}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary">
                                                {dayjs(trip.start_date).format("YYYY / MM / DD")}
                                            </Typography>
                                            {' '} - { trip.description }
                                        </div>}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Paper>
        </Container>
        <CreateTripModal on_created_trip={() => refresh_trips()}/>
    </div>
}