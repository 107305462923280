import React, {useMemo, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, MenuItem, Select,
    TextField
} from "@mui/material";
import {GearCategory, useAddGearCategory, useUpdateGearCategory} from "../../api";
import {createUseStyles} from "react-jss";
import AddIcon from "@mui/icons-material/Add";

const style = createUseStyles({
    fab: {
        position: 'fixed',
        bottom: 25,
        right: 25,
    }
});

interface GearCategoryModalProps {
    existing_categories: GearCategory[];
    category_to_edit?: GearCategory;
    onChange(category: GearCategory): void;
    onClose(): void;
}

const default_category: GearCategory = {
    id: -1,
    name: '',
    description: '',
    parent_id: undefined,
    default_desired_qty: 1,
};

export function GearCategoryModal(props: GearCategoryModalProps) {
    const classes = style();
    const add_gear_category = useAddGearCategory()[1];
    const update_gear_category = useUpdateGearCategory()[1];
    const [is_open, set_is_open] = useState(false);
    const [category, set_category] = useState<GearCategory>(default_category);
    const category_to_edit = useMemo(() => {
        if (props.category_to_edit !== undefined) {
            set_is_open(true);
            return props.category_to_edit;
        }
        return category;
    }, [category, props.category_to_edit]);
    const parent_options = useMemo(() => {
        // parent_map[category_id] = [child ids]
        let parent_map: Record<number, Set<number>> = {};
        props.existing_categories.forEach((v) => {
            if (v.parent_id) {
                if (!parent_map.hasOwnProperty(v.parent_id)) {
                    parent_map[v.parent_id] = new Set<number>();
                }
                parent_map[v.parent_id].add(v.id);
            }
        });
        let child_categories = new Set<number>();
        let search_queue: number[] = [category_to_edit.id];
        while (search_queue.length !== 0) {
            const parent_id = search_queue.pop();
            if (parent_id === undefined) {
                continue;
            }
            if (parent_map.hasOwnProperty(parent_id)) {
                parent_map[parent_id].forEach((v) => child_categories.add(v));
                parent_map[parent_id].forEach((v) => search_queue.push(v));
            }
        }
        return props.existing_categories.filter((v) => v.id !== category_to_edit.id && !child_categories.has(v.id));
    }, [props.existing_categories, category_to_edit]);

    const on_close = () => {
        set_is_open(false);
        props.onClose();
        set_category(default_category);
    };

    const on_confirm_category = () => {
        if (props.category_to_edit) {
            update_gear_category(category_to_edit, () => {
                props.onChange(category_to_edit);
                on_close();
            });
        } else {
            add_gear_category({
                name: category_to_edit.name,
                description: category_to_edit.description,
                parent_id: category_to_edit.parent_id,
                default_desired_qty: category_to_edit.default_desired_qty,
            }, () => {
                props.onChange(category_to_edit);
                on_close();
            });
        }
    }

    const is_category_valid = category_to_edit.name.length > 0;

    return <div>
        <Dialog open={is_open}
                onClose={on_close}>
            <DialogTitle>{props.category_to_edit ? `Edit ${category_to_edit.name}` : 'Add Gear Category'}</DialogTitle>
            <DialogContent>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="name"
                           label="Category Name"
                           fullWidth
                           variant="standard"
                           value={category_to_edit.name}
                           onChange={(e) => set_category({...category_to_edit, name: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="description"
                           label="Description"
                           multiline
                           fullWidth
                           variant="standard"
                           value={category_to_edit.description}
                           onChange={(e) => set_category({...category_to_edit, description: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="default_desired_qty"
                           label="Default Packed Quarntity"
                           fullWidth
                           variant="standard"
                           type="number"
                           value={category_to_edit.default_desired_qty}
                           onChange={(e) => set_category({...category_to_edit, default_desired_qty: Number(e.target.value)})}/>
                <Select value={category_to_edit.parent_id}
                        onChange={(e) => set_category({...category_to_edit, parent_id: Number(e.target.value)})}
                        fullWidth>
                    <MenuItem value={undefined}>No Parent</MenuItem>
                    {parent_options.map((category, i) => (
                        <MenuItem key={i} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={on_close}>Cancel</Button>
                <Button disabled={!is_category_valid}
                        onClick={(e) => on_confirm_category()}>
                    {props.category_to_edit ? 'Update' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>

        {!is_open &&
            <Fab color="secondary"
                 className={classes.fab}
                 aria-label="add-gear-category"
                 onClick={() => {
                     set_category(default_category);
                     set_is_open(true);
                 }}>
                <AddIcon/>
            </Fab>
        }

    </div>
}