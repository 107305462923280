import React, {useMemo, useState} from "react";
import {datetime_format, TripDetails, TripMealGroupEntry, useMealGroups} from "../../api";
import {
    Button,
    Card, CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Stack, TextField, Typography
} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {createUseStyles} from "react-jss";
import Box from "@mui/material/Box";

const row_height = 250;
const style = createUseStyles({
    row_header: {
        height: row_height - 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
    },
    col_header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        height: 15,
    },
    card: {
        height: row_height,
        width: 270,
        marginBottom: 5,
    }
})

interface MealEntryProps {
    entry: TripMealGroupEntry;
    onChange(entry: TripMealGroupEntry): void;
}

export function MealEntry({entry, onChange, ...rest}: MealEntryProps) {
    const classes = style();
    const [name, set_name] = useState(entry.name);
    const [calories, set_calories] = useState(entry.calories);
    const [weight, set_weight] = useState(entry.weight);

    return <Box padding={1} className={classes.card} {...rest}>
        <Card>
            <CardContent>
                <TextField label="Meal Name"
                           fullWidth
                           value={name}
                           onChange={(e) => set_name(e.target.value)}
                           onBlur={(e) => onChange({...entry, name: e.target.value})}/>
            </CardContent>
            <CardActions>
                <Stack direction="column">
                    <Box padding={1}>
                        <TextField type="number"
                                   fullWidth
                                   label="Calories"
                                   value={calories}
                                   onChange={(e) => set_calories(Number(e.target.value))}
                                   onBlur={(e) => onChange({...entry, calories: Number(e.target.value)})}/>
                    </Box>
                    <Box padding={1}>
                        <TextField type="number"
                                   fullWidth
                                   label="Weight (oz)"
                                   value={weight}
                                   onChange={(e) => set_weight(Number(e.target.value))}
                                   onBlur={(e) => onChange({...entry, weight: Number(e.target.value)})}/>
                    </Box>
                </Stack>
            </CardActions>
        </Card>
    </Box>
}

interface DayMenuProps {
    date: Dayjs;
    meals: TripMealGroupEntry[];
}

function DayMenu(props: {day_menu: DayMenuProps, onChange: (entry: TripMealGroupEntry) => void }) {
    const classes = style();
    return <Stack direction="column">
        <div className={classes.col_header}>
            <Typography><b>{props.day_menu.date.format('MM/DD')}</b></Typography>
        </div>
        {props.day_menu.meals.map((meal, j) => (
            <MealEntry entry={meal} onChange={props.onChange} key={j}/>
        ))}
        <Box padding={1}>
            <Typography><b>Day's calories: </b>{props.day_menu.meals.reduce((p, v) => p + v.calories, 0.0).toFixed(0)}</Typography>
            <Typography><b>Day's weight: </b>{props.day_menu.meals.reduce((p, v) => p + v.weight, 0.0).toFixed(2)} oz</Typography>
        </Box>
    </Stack>
}

interface MenuEditorProps {
    trip_details: TripDetails;
    is_open: boolean;
    onClose(): void;
    onChange(entry: TripMealGroupEntry): void;
}

export function MenuEditor(props: MenuEditorProps) {
    const classes = style();
    const meal_groups = useMealGroups()[0];

    const day_menus = useMemo(() => {
        const find_meal = (meal_date: string, meal_group_id: number): TripMealGroupEntry => {
            for (const meal of props.trip_details.menu) {
                if (meal.meal_group_id === meal_group_id && meal.meal_date === meal_date) {
                    return meal;
                }
            }
            return {
                id: -1,
                trip_id: props.trip_details.trip.id,
                meal_group_id: meal_group_id,
                name: '',
                weight: 0.0,
                calories: 0.0,
                meal_date: meal_date,
            };
        }
        const start_day = dayjs(props.trip_details.trip.start_date, datetime_format);
        const end_day = dayjs(props.trip_details.trip.end_date, datetime_format);
        const n_days = end_day.diff(start_day, 'day') + 1;
        let days: DayMenuProps[] = [];
        for (let day_n = 0; day_n < n_days && meal_groups; ++day_n) {
            let day: DayMenuProps = {date: start_day.add(day_n, 'day'), meals: []};
            const date_str = day.date.format(datetime_format);
            for (const group of meal_groups) {
                day.meals.push(find_meal(date_str, group.id));
            }
            days.push(day);
        }
        return days;
    }, [meal_groups, props.trip_details]);

    return <div>
        <Dialog open={props.is_open}
                onClose={props.onClose}
                maxWidth={false}>
            <DialogTitle>{props.trip_details.trip.name} Menu</DialogTitle>
            <DialogContent>
                {meal_groups &&
                    <Stack direction="row">
                        <div>
                            <div className={classes.col_header} style={{marginBottom: 15}}/>
                            <hr/>
                            {meal_groups.map((meal_group, i) => (
                                <div key={i}>
                                    <div className={classes.row_header}>
                                        <Typography>{meal_group.name}</Typography>
                                    </div>
                                <hr/>
                                </div>
                            ))}
                        </div>
                        {day_menus.map((day_menu, i) => (
                            <DayMenu key={i} day_menu={day_menu} onChange={props.onChange}/>
                            ))}
                    </Stack>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}