import React from "react";
import Box from '@mui/material/Box';
import {AppBar, Toolbar, Tabs, Tab, Typography, IconButton} from "@mui/material";
import {createUseStyles} from "react-jss";
import {Link} from "react-router-dom";
import {useUserInfo} from "../../api";

const style = createUseStyles({
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tabs: {
    }
});

export function Navbar() {
    const classes = style();
    const path_parts = window.location.pathname.split('/').filter(p => p.length !== 0);
    const [selected, setSelected] = React.useState(path_parts.length >= 1 ? path_parts[0] : 'trips');
    const {user} = useUserInfo();

    return <Box>
        <AppBar position="fixed"
                color="primary"
                enableColorOnDark
                elevation={6}
                sx={{top: 0, bottom: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar className={classes.toolbar}>
                <IconButton onClick={() => setSelected('trips')}
                            component={Link}
                            to="/">
                    <img style={{'height': 48}} src="/logo192.png"
                         alt=""/>
                </IconButton>
                <Tabs value={selected}
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="scrollable"
                      scrollButtons="auto">
                    <Tab value="gear" label="Gear" component={Link} to="/gear" onClick={() => setSelected('gear')}/>
                    <Tab value="trips" label="Trips" component={Link} to="/trips" onClick={() => setSelected('trips')}/>
                    {user.is_admin &&
                        <Tab value="admin" label="Administration" component={Link} to="/admin" onClick={() => setSelected('admin')}/>
                    }
                </Tabs>
                <div>
                    <Typography>
                        {user.display_name}
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    </Box>
}
