import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {createUseStyles} from "react-jss";
import {useAddTrip} from "../../api";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Dayjs} from "dayjs";


const style = createUseStyles({
    fab: {
        position: 'fixed',
        bottom: 25,
        right: 25,
    }
})

interface CreateTripModalProps {
    on_created_trip(): void;
}

export function CreateTripModal(props: CreateTripModalProps) {
    const classes = style();
    const add_trip = useAddTrip()[1];
    const [is_open, set_is_open] = useState(false);
    const [name, set_name] = useState('');
    const [description, set_description] = useState('');
    const [start_date, set_start_date] = useState<Dayjs | null>(null);
    const [end_date, set_end_date] = useState<Dayjs | null>(null);

    const on_close = () => {
        set_is_open(false);
    };

    const is_new_trip_valid = name.length !== 0 && start_date !== null && end_date !== null;
    const create_new_trip = () => {
        if (is_new_trip_valid) {
            add_trip({
                name: name,
                description: description,
                start_date: start_date.format("YYYY-MM-DD 00:00"),
                end_date: end_date.format("YYYY-MM-DD 00:00"),
            }, () => {
                props.on_created_trip()
                on_close();
            });
        }
    }

    return <div>
        <Dialog open={is_open}
                onClose={on_close}>
            <DialogTitle>Create Trip</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Let's go on a trip! Fill in the info below to get started!
                </DialogContentText>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="name"
                           name="name"
                           label="Trip Name"
                           fullWidth
                           variant="standard"
                           value={name}
                           onChange={(e) => set_name(e.target.value)}/>
                <TextField required
                           multiline
                           margin="dense"
                           id="description"
                           name="description"
                           label="descritpion"
                           fullWidth
                           variant="standard"
                           value={description}
                           onChange={(e) => set_description(e.target.value)}/>
                <DatePicker label="Start Date"
                            value={start_date}
                            onChange={(new_start_date) => set_start_date(new_start_date)}/>
                <DatePicker label="End Date"
                            value={end_date}
                            onChange={(new_end_date) => set_end_date(new_end_date)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={on_close}>Cancel</Button>
                <Button disabled={!is_new_trip_valid}
                        onClick={() => create_new_trip()}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        {!is_open &&
            <Fab color="secondary"
                 className={classes.fab}
                 aria-label="add-trip"
                 onClick={() => set_is_open(true)}>
                <AddIcon/>
            </Fab>
        }
    </div>;
}