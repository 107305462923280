import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem, Select,
    TextField
} from "@mui/material";
import {
    datetime_format,
    GearCategory,
    GearItem,
    useAddGearWeight,
    useGearItemWeights,
    useUpdateGearItem
} from "../../api";
import dayjs from "dayjs";

interface EditGearItemModalProps {
    existing_categories: Record<number, GearCategory>;
    item_to_edit: GearItem;

    onChange(item: GearItem): void;

    onClose(): void;

    onSave(item: GearItem): void;
}

export function EditGearItemModal(props: EditGearItemModalProps) {
    const update_gear_item = useUpdateGearItem()[1]
    const add_weight_measurement = useAddGearWeight()[1];
    const gear_weights = useGearItemWeights(props.item_to_edit.id)[0];
    const [current_weight, set_current_weight] = useState(0.0);
    const [is_weight_dirty, set_is_weight_dirty] = useState(false);

    useEffect(() => {
        if (gear_weights && gear_weights.length > 0) {
            let most_recent_weight = gear_weights[0];
            let most_recent_weight_date = dayjs(most_recent_weight.datetime, datetime_format);
            for (const weight of gear_weights) {
                const entry_date = dayjs(weight.datetime, datetime_format);
                if (most_recent_weight_date.isBefore(entry_date)) {
                    most_recent_weight = weight;
                    most_recent_weight_date = entry_date
                }
            }
            set_current_weight(most_recent_weight.weight);
        }
    }, [gear_weights]);

    const on_confirm_item = () => {
        if (props.item_to_edit) {
            update_gear_item(props.item_to_edit, () => {
                props.onClose();
            });
            if (is_weight_dirty) {
                add_weight_measurement({
                    gear_id: props.item_to_edit.id,
                    weight: current_weight,
                }, () => {});
            }
            props.onSave(props.item_to_edit);
        }
    }

    const is_item_valid = props.item_to_edit
        && props.item_to_edit.make.length > 0
        && props.item_to_edit.model.length > 0
        && props.item_to_edit.description.length > 0
        && props.item_to_edit.category_id >= 0;

    return <div>
        <Dialog open={true}
                onClose={props.onClose}>
            <DialogTitle>{`Edit ${props.item_to_edit?.make} - ${props.item_to_edit?.model}`}</DialogTitle>
            <DialogContent>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="make"
                           label="Make"
                           fullWidth
                           variant="standard"
                           value={props.item_to_edit?.make}
                           onChange={(e) => props.onChange({...props.item_to_edit, make: e.target.value})}/>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="model"
                           label="Model"
                           fullWidth
                           variant="standard"
                           value={props.item_to_edit.model}
                           onChange={(e) => props.onChange({...props.item_to_edit, model: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="description"
                           label="Description"
                           multiline
                           fullWidth
                           variant="standard"
                           value={props.item_to_edit.description}
                           onChange={(e) => props.onChange({...props.item_to_edit, description: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="weight"
                           label="Weight"
                           fullWidth
                           variant="standard"
                           type="number"
                           value={current_weight}
                           onChange={(e) => {
                               set_current_weight(Number(e.target.value));
                               set_is_weight_dirty(true);
                           }}/>
                <Select value={props.item_to_edit.category_id}
                        onChange={(e) => props.onChange({...props.item_to_edit, category_id: Number(e.target.value)})}
                        fullWidth>
                    <MenuItem value={-1}>Unset</MenuItem>
                    {Object.values(props.existing_categories).map((category, i) => (
                        <MenuItem key={i} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button disabled={!is_item_valid}
                        onClick={() => on_confirm_item()}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}