import React, {useState} from "react";
import {
    GearCategory,
    GearItem,
    GearWeightMeasurement,
    TripDetails,
    TripMealGroupEntry,
    useUpdateMealPlan,
    useUpdateTrip
} from "../../api";
import {Button, List, ListItem, ListItemText, TextField} from "@mui/material";
import {MenuEditor} from "./MenuEditor";
import {TripViewCard} from "./TripViewCard";


function WeightDisplay(props: {title: string, weight_oz: number, action?: any, color?: string}) {
    const sub = <span><b>{(props.weight_oz / 16.0).toFixed(2)} Lbs.</b> - {props.weight_oz.toFixed(2)} oz</span>
    return <ListItem secondaryAction={props.action}>
        <ListItemText primary={props.title}
                      secondary={sub}
                      primaryTypographyProps={{
                          color: props.color ? props.color : "default"
        }}/>
    </ListItem>
}

interface TripWeightBreakdownProps {
    trip_details: TripDetails;
    gear_categories: Record<number, GearCategory>;
    gear: Record<number, GearItem>;
    gear_weights: Record<number, GearWeightMeasurement>;
    on_needs_refresh(): void;
}

export function TripWeightBreakdown(props: TripWeightBreakdownProps) {
    const update_trip = useUpdateTrip()[1];
    const update_meal = useUpdateMealPlan()[1];
    const [is_menu_open, set_menu_open] = useState(false);

    let base_weight = 0.0;
    let base_wear_weight = 0.0;
    let food_weight = 0.0;
    let [water_weight, set_water_weight] = useState(props.trip_details.trip.water_weight);
    let [fuel_weight, set_fuel_weight] = useState(props.trip_details.trip.fuel_weight);
    let is_missing_measurement = false;
    for (const category of props.trip_details.gear) {
        if (category.gear_id === undefined || category.gear_id === null) {
            // Don't need to do anything here.
        } else if (props.gear_weights.hasOwnProperty(category.gear_id)) {
            const gear_weight = props.gear_weights[category.gear_id].weight
            // const gear = props.gear[category.gear_id];
            if (category.weight_category === 'pack') {
                base_weight += gear_weight;
            } else if (category.weight_category === 'carry') {
                base_wear_weight += gear_weight;
            }
        } else {
            is_missing_measurement = true;
            console.warn("Missing weight measurement for gear_id: " + category.gear_id);
        }
    }

    for (const meal_entry of props.trip_details.menu) {
        food_weight += meal_entry.weight;
    }

    const total = base_weight + food_weight + water_weight + fuel_weight;

    const on_update_meal = (meal: TripMealGroupEntry) => {
        update_meal(meal, () => {
            props.on_needs_refresh();
        });
    }

    return <TripViewCard flexGrow={2} minWidth={400}>

        <List>
            <WeightDisplay title="Base weight"
                           weight_oz={base_weight}
                           color={is_missing_measurement ? "red" : "default"}/>
            <WeightDisplay title="Base weight with wear"
                           weight_oz={base_wear_weight + base_weight}
                           color={is_missing_measurement ? "red" : "default"}/>
            <WeightDisplay title="Food"
                           weight_oz={food_weight}
                           action={<Button onClick={() => set_menu_open(true)}>
                               Open Menu
                           </Button>}/>
            <WeightDisplay title="Fuel"
                           weight_oz={fuel_weight}
                           action={<TextField label="Fuel (oz)"
                                              type="number"
                                              variant="standard"
                                              value={fuel_weight}
                                              onChange={(e) => set_fuel_weight(Number(e.target.value))}
                                              onBlur={(e) => {
                                                  set_fuel_weight(Number(e.target.value));
                                                  update_trip({...props.trip_details.trip, fuel_weight: fuel_weight}, () => {});
                                                  props.on_needs_refresh();
                                              }}/>
                           }/>
            <WeightDisplay title="Water"
                           weight_oz={water_weight}
                           action={<TextField label="Water (oz)"
                                              type="number"
                                              variant="standard"
                                              value={water_weight}
                                              onChange={(e) => set_water_weight(Number(e.target.value))}
                                              onBlur={(e) => {
                                                  set_water_weight(Number(e.target.value));
                                                  update_trip({...props.trip_details.trip, water_weight: water_weight}, () => {});
                                                  props.on_needs_refresh();
                                              }}/>
                           }/>
            <WeightDisplay title="Total"
                           color={is_missing_measurement ? "red" : "default"}
                           weight_oz={total}/>
            <WeightDisplay title="Total Skin-out"
                           color={is_missing_measurement ? "red" : "default"}
                           weight_oz={total + base_wear_weight}/>
        </List>
        <MenuEditor trip_details={props.trip_details}
                    is_open={is_menu_open}
                    onClose={() => set_menu_open(false)}
                    onChange={on_update_meal}/>
    </TripViewCard>
}