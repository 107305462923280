import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab, MenuItem, Select,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {createUseStyles} from "react-jss";
import {GearCategory, Trip, useAddTripGear} from "../../api";

const style = createUseStyles({
    fab: {
        position: 'fixed',
        bottom: 25,
        right: 25,
    }
})

interface AddGearCategoryProps {
    trip: Trip;
    gear_categories: Record<number, GearCategory>;
    onRefreshNeeded(): void;
}

export function AddGearCategory(props: AddGearCategoryProps) {
    const classes = style();
    const [is_open, set_is_open] = useState(false);
    const [gear_category_id, set_gear_category_id] = useState<number | undefined>(undefined);
    const add_gear_category = useAddTripGear()[1];

    const on_close = () => {
        set_is_open(false);
    };

    const add_category = () => {
        if (gear_category_id !== undefined) {
            add_gear_category({
                trip_id: props.trip.id,
                gear_category_id: gear_category_id
            }, () => {
                on_close();
                props.onRefreshNeeded();
            });
        }
    }

    return <div>
        <Dialog open={is_open}
                onClose={on_close}>
            <DialogTitle>Create Trip</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add a category of gear that needs to go on your trip
                </DialogContentText>
                <Select value={gear_category_id}
                        fullWidth
                        onChange={(e) => {set_gear_category_id(Number(e.target.value))}}>
                    {Object.values(props.gear_categories).map((category, i) => (
                        <MenuItem key={i} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={on_close}>Cancel</Button>
                <Button disabled={gear_category_id === undefined}
                        onClick={(e) => add_category()}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        {!is_open &&
            <Fab color="secondary"
                 className={classes.fab}
                 aria-label="add-trip"
                 onClick={() => set_is_open(true)}>
                <AddIcon/>
            </Fab>
        }
    </div>
}