import React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";


interface ConfirmDialogProps {
    is_open: boolean;
    title: string;
    content: string;
    onClose(): void;
    onConfirmed(): void;
    is_busy?: boolean;
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const is_busy = props.is_busy === undefined ? false : props.is_busy;
    return <Dialog open={props.is_open}
                   onClose={props.onClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose}
                    disabled={is_busy}>No</Button>
            {!is_busy &&
                <Button color="warning"
                        onClick={props.onConfirmed}>
                    Yes, Delete
                </Button>
            }
            {is_busy &&
                <Button color="warning"
                        disabled
                        endIcon={<CircularProgress size={20}/>}>
                    Yes, Delete
                </Button>
            }
        </DialogActions>
    </Dialog>
}