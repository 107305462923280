import React, {useState} from "react";
import {IconButton, List, ListItem, ListItemText, Paper, Container} from "@mui/material";
import {useDeleteUser, User, useUsers} from "../../api";
import DeleteIcon from "@mui/icons-material/Delete";
import {ConfirmDialog} from "../common/ConfirmDialog";
import Box from "@mui/material/Box";
import {CreateUserModal} from "./CreateUserModal";


export function Administration() {
    const [users, refresh_users] = useUsers();
    const delete_user = useDeleteUser();
    const [user_to_delete, set_user_to_delete] = useState<User | undefined>(undefined);
    return <Container>
        {users !== undefined &&
            <Box maxWidth="md">
                <Paper elevation={4}>
                    <List>
                        {users.map((user, i) => (
                            <ListItem key={i}
                                      secondaryAction={<IconButton onClick={() => set_user_to_delete(user)}>
                                <DeleteIcon/>
                            </IconButton>}>
                                <ListItemText primary={user.display_name}
                                              secondary={user.username}/>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
                <CreateUserModal current_users={users}
                                 on_created_user={() => {
                                     refresh_users();
                                 }}/>
            </Box>
        }
        <ConfirmDialog is_open={user_to_delete !== undefined}
                       title={`Delete user "${user_to_delete?.display_name}"?`}
                       content={`Are you sure you want to delete "${user_to_delete?.username}"?`}
                       onClose={() => set_user_to_delete(undefined)}
                       onConfirmed={() => {
                           if (user_to_delete) {
                               delete_user(user_to_delete.id, () => {
                                   set_user_to_delete(undefined);
                                   refresh_users();
                               });
                           }
                       }}/>
    </Container>
}