import React, {useState} from "react";
import {
    GearCategory,
    useDeleteGearCategory,
    useGearCategories,
} from "../../api";
import {ConfirmDialog} from "../common/ConfirmDialog";
import {Container, IconButton, List, ListItem, ListItemButton, ListItemText, Paper} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {GearCategoryModal} from "./GearCategoryModal";

export function GearCategoriesList() {
    const [gear_categories, update_gear_categories] = useGearCategories();
    const delete_gear_category = useDeleteGearCategory();
    const [category_to_delete, set_category_to_delete] = useState<GearCategory | undefined>(undefined);
    const [category_to_edit, set_category_to_edit] = useState<GearCategory | undefined>(undefined);
    const [is_deleting_category, set_is_deleting_category] = useState(false);

    return <Container maxWidth="lg">
        <Paper elevation={4}>
            {gear_categories !== undefined &&
                <div>
                    <List>
                        {gear_categories.map((category, i) => (
                            <ListItem key={i}
                                      secondaryAction={<IconButton onClick={() => set_category_to_delete(category)}>
                                          <DeleteIcon/>
                                      </IconButton>}>
                                <ListItemButton onClick={() => set_category_to_edit(category)}>
                                    <ListItemText primary={category.name}
                                                  secondary={category.description}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <GearCategoryModal existing_categories={gear_categories}
                                       category_to_edit={category_to_edit}
                                       onClose={() => set_category_to_edit(undefined)}
                                       onChange={() => update_gear_categories()}/>
                </div>
            }
        </Paper>
        <ConfirmDialog is_open={category_to_delete !== undefined}
                       title={`Delete ${category_to_delete?.name}?`}
                       content={`Are you sure you want to delete "${category_to_delete?.name}"?`}
                       onClose={() => set_category_to_delete(undefined)}
                       onConfirmed={() => {
                           if (category_to_delete !== undefined) {
                               set_is_deleting_category(true);
                               delete_gear_category(category_to_delete.id, () => {
                                   set_category_to_delete(undefined);
                                   update_gear_categories();
                                   set_is_deleting_category(false);
                               });
                           }
                       }}
                       is_busy={is_deleting_category}/>
    </Container>
}