import React from "react";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {
    GearCategory,
    GearItem, GearWeightMeasurement,
    useGear,
    useGearCategories,
    useListToMap,
    useTripDetails
} from "../../api";
import {TripDetailsEditor} from "./TripDetailsEditor";
import {TripGearList} from "./TripGearList";
import {createUseStyles} from "react-jss";
import {TripWeightBreakdown} from "./TripWeightBreakdown";
import {AddGearCategory} from "./AddGearCategory";

const style = createUseStyles({
    layout: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    progress: {
        display: "flex",
        justifyContent: 'center'
    }
});

export function TripView() {
    const classes = style();
    const route_params = useParams();
    const [trip_details, update_trip_details] = useTripDetails(route_params.trip_id);
    const gear_categories = useListToMap(useGearCategories()[0], (v: GearCategory) => v.id);
    const gear_map = useListToMap(useGear()[0], (v: GearItem) => v.id);
    const gear_weights = useListToMap(trip_details?.weights, (v: GearWeightMeasurement) => v.gear_id);

    const has_all_data = trip_details !== undefined
        && Object.values(gear_categories).length !== 0
        && Object.values(gear_map).length !== 0
        && Object.values(gear_weights).length !== 0;

    return <div>
        {!has_all_data &&
            <div className={classes.progress}>
                <CircularProgress size={80}/>
            </div>
        }
        {has_all_data &&
            <div className={classes.layout}>
                <TripDetailsEditor trip={trip_details.trip}
                                   on_needs_refresh={() => update_trip_details()}/>
                <TripWeightBreakdown trip_details={trip_details}
                                     gear_categories={gear_categories}
                                     gear={gear_map}
                                     gear_weights={gear_weights}
                                     on_needs_refresh={() => update_trip_details()}/>
                <TripGearList trip_details={trip_details}
                              gear_categories={gear_categories}
                              gear={gear_map}
                              gear_weights={gear_weights}
                              on_needs_refresh={() => update_trip_details()}/>
                <AddGearCategory trip={trip_details.trip}
                                 gear_categories={gear_categories}
                                 onRefreshNeeded={() => update_trip_details()}/>
            </div>
        }
    </div>
}