import React, {useMemo, useState} from "react";
import {GearItem, useDeleteGearItem, useGear, useGearCategories, useListToMap} from "../../api";
import {Collapse, Container, IconButton, List, ListItem, ListItemButton, ListItemText, Paper} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {ConfirmDialog} from "../common/ConfirmDialog";
import {EditGearItemModal} from "./EditGearItemModal";
import {AddGearItemModal} from "./AddGearItemModal";

export function GearList() {
    const gear_categories = useListToMap(useGearCategories()[0], (v) => v.id);
    const delete_gear_item = useDeleteGearItem();
    const [gear_items, update_gear_items] = useGear();
    const [gear_item_to_delete, set_gear_item_to_delete] = useState<GearItem | undefined>(undefined);
    const [gear_item_to_edit, set_gear_item_to_edit] = useState<GearItem | undefined>(undefined);
    const [is_deleting_gear_item, set_is_deleting_gear_item] = useState(false);
    const [group_open_map, set_group_open_map] = useState<Record<number, boolean>>({});

    const grouped_gear_items = useMemo(() => {
        let groups: Record<number, GearItem[]> = {};
        if (gear_items) {
            for (const item of gear_items) {
                if (!groups.hasOwnProperty(item.category_id)) {
                    groups[item.category_id] = [];
                }
                groups[item.category_id].push(item);
            }
        }
        return groups;
    }, [gear_items]);

    const handle_group_click = (category_id: number) => {
        const current_state = is_group_open(category_id);
        group_open_map[Number(category_id)] = !current_state;
        set_group_open_map(group_open_map);
    }
    const is_group_open = (category_id: number) => {
        return group_open_map.hasOwnProperty(category_id) ? group_open_map[category_id] : true;
    }

    return <Container maxWidth="lg">
        <Paper elevation={4}>
            {gear_categories && gear_items &&
                <div>
                    <List>
                        {Object.keys(gear_categories).map((category_id_str, i) => {
                            const category_id = Number(category_id_str);
                            const category = gear_categories[category_id];
                            const group_gear_items = grouped_gear_items.hasOwnProperty(category_id) ? grouped_gear_items[category_id] : [];
                            return <div key={i}>
                                {/* TODO: something isn't letting us expand nor collapse */}
                                <ListItemButton onClick={() => handle_group_click(category_id)}>
                                    <ListItemText primary={category.name}
                                                  secondary={category.description}/>
                                    {/*{is_group_open(category_id) ? <ExpandLess/> : <ExpandMore/>}*/}
                                </ListItemButton>
                                <Collapse in={is_group_open(category_id)}>
                                    <List>
                                        {group_gear_items.map((item, j) => (
                                            <ListItem key={j}
                                                      secondaryAction={<IconButton
                                                          onClick={() => set_gear_item_to_delete(item)}>
                                                          <DeleteIcon/>
                                                      </IconButton>}>
                                                <ListItemButton onClick={() => set_gear_item_to_edit(item)}>
                                                    <ListItemText primary={`${item.make} - ${item.model}`}
                                                                  secondary={item.description}/>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        })}
                    </List>
                    {gear_item_to_edit &&
                        <EditGearItemModal existing_categories={gear_categories}
                                           item_to_edit={gear_item_to_edit}
                                           onChange={() => update_gear_items()}
                                           onClose={() => set_gear_item_to_edit(undefined)}
                                           onSave={() => set_gear_item_to_edit(undefined)}/>
                    }
                    <AddGearItemModal existing_categories={gear_categories}
                                      onChange={() => update_gear_items()}
                                      onClose={() => {}}/>
                </div>
            }
        </Paper>
        <ConfirmDialog is_open={gear_item_to_delete !== undefined}
                       title={`Delete ${gear_item_to_delete?.make} ${gear_item_to_delete?.model}?`}
                       content={`Are you sure you want to delete ${gear_item_to_delete?.make} ${gear_item_to_delete?.model}?`}
                       onClose={() => set_gear_item_to_delete(undefined)}
                       onConfirmed={() => {
                           if (gear_item_to_delete !== undefined) {
                               set_is_deleting_gear_item(true);
                               delete_gear_item(gear_item_to_delete.id, () => {
                                   set_gear_item_to_delete(undefined);
                                   update_gear_items();
                                   set_is_deleting_gear_item(false);
                               });
                           }
                       }}
                       is_busy={is_deleting_gear_item}/>
    </Container>
}