import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Alert, AlertTitle} from "@mui/material";
import {LoginPage} from "./login";
import {createUseStyles} from "react-jss";
export const base_api_url = process.env.REACT_APP_API_BASE;

export interface User {
    id: number;
    date_joined: string;  // "YYYY-MM-DD"
    username: string;
    display_name: string;
    is_admin: boolean;
}

export interface GearCategory {
    id: number;
    name: string;
    description: string;
    parent_id?: number;
    default_desired_qty: number;
}

export interface GearItem {
    id: number;
    category_id: number;
    make: string;
    model: string;
    date_added: number;  // "YYYY-MM-DD"
    description: string;
}

export interface GearWeightMeasurement {
    id: number;
    gear_id: number;
    datetime: string;  // "YYYY-MM-DD"
    weight: number;
}

export interface MealGroup {
    id: number;
    name: string;
    order_number: number;
}

export interface AddTripForm {
    name: string;
    description: string;
    start_date: string;
    end_date: string;
}

export interface Trip extends AddTripForm{
    id: number;
    fuel_weight: number;
    water_weight: number;
}

export type PackCarryType = "pack" | "carry";

export interface TripGearCategory {
    id: number;
    trip_id: number;
    gear_category_id: number;
    gear_id: number | undefined;
    weight_category: PackCarryType;
    is_packed: boolean;
}

export interface AddTripGearCategoryForm {
    trip_id: number;
    gear_category_id: number;
}

export interface TripMealGroupEntry {
    id: number;
    trip_id: number;
    meal_group_id: number;
    name: string;
    weight: number;
    calories: number;
    meal_date: string;  // "YYYY-MM-DD"
}

export interface TripDetails {
    trip: Trip;
    gear: TripGearCategory[];
    menu: TripMealGroupEntry[];
    weights: GearWeightMeasurement[];
}
export const datetime_format = 'YYYY-MM-DDTHH:mm:ss';

interface ErrorContext {
    set_error_msg(msg: string): void;
    set_error_title(title: string): void;
}

const ErrorStatusContext = createContext<ErrorContext>({
    set_error_msg: (msg) => {console.error(msg)},
    set_error_title: (title) => {console.error(title)}
});

const error_style = createUseStyles({
    alert: {
        width: '30%',
        position: 'absolute',
        bottom: 20,
        left: 20,
    }
})

export function ErrorHandler(props: {children?: any}) {
    const classes = error_style();
    const [ error_msg, set_error_msg] = useState('');
    const [error_title, set_error_title] = useState('');
    const contextPayload = useMemo(() => ({set_error_msg, set_error_title}),
        [set_error_msg, set_error_title]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            set_error_title('');
        }, 5000);
        return () => {
            clearTimeout(timerId);
        }
    }, []);
    return <ErrorStatusContext.Provider value={contextPayload}>
        {props.children}
        {error_title &&
            <Alert variant="filled"
                   className={classes.alert}
                   severity="error"
                   onClose={() => set_error_title('')}>
                <AlertTitle>{error_title}</AlertTitle>
                {error_msg}
            </Alert>
        }
    </ErrorStatusContext.Provider>
}
export const useErrorStatus = () => useContext(ErrorStatusContext);

interface UserInfoContextData {
    jwt_token: string;
    user: User;
}
const default_user: User = {id: -1, date_joined: "", display_name: "", is_admin: false, username: ""};
const get_jwt_token_from_local_storage = (): string => {
    const token = localStorage.getItem('jwt_token');
    if (!token) return '';
    return token;
}
const UserInfoContext = createContext<UserInfoContextData>({
    jwt_token: get_jwt_token_from_local_storage(),
    user: default_user
});
export function LoginHandler(props: {children?: any}) {
    const [ jwt_token, set_token] = useState(get_jwt_token_from_local_storage());
    const [ user, set_user ] = useState<User>(default_user);
    const [ error_msg, set_error_msg ] = useState('');
    const contextPayload = useMemo(() => ({jwt_token, user}), [jwt_token, user]);

    const on_login = (username: string, password: string ) => {
        fetch(base_api_url + '/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({'username': username, 'password': password})
        }).then(response => {
            response.json().then(data => {
                if (response.ok) {
                    set_token(data.token.access_token);
                    localStorage.setItem('jwt_token', data.token.access_token);
                    set_user(data.user);
                } else {
                    console.error('failed to login: ' + JSON.stringify(data));
                    set_error_msg(data.detail);
                }
            }).catch(e => {
                console.error('failed to get login: ' + e);
                set_error_msg('Failed to login');
            });
        }).catch(e => {
            console.error('failed to get login: ' + e);
            set_error_msg('Failed to login');
        });
    }

    if (user.id === -1) {
        fetch(base_api_url + '/get_user', {
            headers: {
                'Authorization': 'Bearer ' + jwt_token
            }
        }).then(response => {
            response.json().then(data => {
                if (response.ok) {
                    set_user(data);
                } else {
                    console.error('failed to get user: ' + JSON.stringify(data));
                    set_error_msg(data.detail);
                    set_token('');
                    localStorage.setItem('jwt_token', '');
                }
            }).catch(e => {
                console.error('failed to get user: ' + e);
                set_token('');
                localStorage.setItem('jwt_token', '');
            });
        }).catch(e => {
            console.error('failed to get login: ' + e);
            set_token('');
            localStorage.setItem('jwt_token', '');
        });

    }

    return <UserInfoContext.Provider value={contextPayload}>
        {(user.id === -1 || jwt_token.length === 0) &&
            <LoginPage login_callback={on_login}
                       error_msg={error_msg}/>
        }
        {props.children}

    </UserInfoContext.Provider>
}
export const useUserInfo = () => useContext(UserInfoContext);

function usePost<S, B>(props: {endpoint: string, name: string, initial_state: S}): [S, (body: B, on_success: (response: S) => void) => void] {
    const { set_error_msg, set_error_title } = useErrorStatus();
    const {jwt_token} = useUserInfo();
    const [ api_data, set_api_data ] = useState<S>(props.initial_state);
    const endpoint = props.endpoint;

    const fetch_endpoint = (body: B, on_success: (response: S) => void) => {
        fetch(base_api_url + endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt_token,
            }
        }).then(response => {
            response.json().then(result => {
                if (response.ok) {
                    set_api_data(result);
                    on_success(result);
                } else {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(result));
                    set_error_msg(result.detail);
                    set_error_title('Failed to get ' + props.name);
                }
            }).catch(e => {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(e));
                    set_error_msg(JSON.stringify(e));
                    set_error_title('Failed to get ' + props.name);
            });
        }).catch(e => {
            console.error('failed to get ' + endpoint + ': ' + e);
            set_error_msg(JSON.stringify(e));
            set_error_title('Failed to get ' + props.name);
        });
    }

    return [api_data, fetch_endpoint];
}

function useGet<S>(props: {endpoint: string, name: string, initial_state: S}): [S, () => void] {
    const { set_error_msg, set_error_title } = useErrorStatus();
    const {jwt_token} = useUserInfo();
    const [ api_data, set_api_data ] = useState<S>(props.initial_state);
    const endpoint = props.endpoint;

    const fetch_endpoint = () => {
        fetch(base_api_url + endpoint, {
            headers: {
                'Authorization': 'Bearer ' + jwt_token
            }
        }).then(response => {
            response.json().then(result => {
                if (response.ok) {
                    set_api_data(result);
                } else {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(result));
                    set_error_msg(result.detail);
                    set_error_title('Failed to get ' + props.name);
                }
            }).catch(e => {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(e));
                    set_error_msg(JSON.stringify(e));
                    set_error_title('Failed to get ' + props.name);
            });
        }).catch(e => {
            console.error('failed to get ' + endpoint + ': ' + e);
            set_error_msg(JSON.stringify(e));
            set_error_title('Failed to get ' + props.name);
        });
    }

    // 'props.endpoint is set as a dependency so that if the endpoint changes (like with url params)
    // then this hook re-fetches the data.
    useEffect(() => {
        set_api_data(props.initial_state);
        fetch_endpoint();
        // eslint-disable-next-line
    }, [props.endpoint]);
    return [api_data, fetch_endpoint];
}

function useDelete(props: {name: string}): (endpoint: string, on_success: () => void) => void {
    const { set_error_msg, set_error_title } = useErrorStatus();
    const {jwt_token} = useUserInfo();

    return (endpoint: string, on_success: () => void) => {
        fetch(base_api_url + endpoint, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + jwt_token
            }
        }).then(response => {
            response.json().then(result => {
                if (response.ok) {
                    on_success();
                } else {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(result));
                    set_error_msg(result.detail);
                    set_error_title('Failed to get ' + props.name);
                }
            }).catch(e => {
                    console.error('failed to get ' + endpoint + ': ' + JSON.stringify(e));
                    set_error_msg(JSON.stringify(e));
                    set_error_title('Failed to get ' + props.name);
            });
        }).catch(e => {
            console.error('failed to get ' + endpoint + ': ' + e);
            set_error_msg(JSON.stringify(e));
            set_error_title('Failed to get ' + props.name);
        });
    }
}

export function useListToMap<K extends string | number | symbol, V>(values: V[] | undefined, key_getter: (v: V) => (string | number)) {
    return useMemo(() => {
        // @ts-ignore
        let m: Record<K, V> = {};
        if (values) {
            for (const v of values) {
                // @ts-ignore
                m[key_getter(v)] = v;
            }
        }
        return m;
    }, [values, key_getter]);
}

/// Trip stuff
export function useGetTrips() {
    return useGet<Trip[] | undefined>({endpoint: '/trip/', name: 'Get Trip List', initial_state: undefined});
}

export function useAddTrip() {
    return usePost<undefined, AddTripForm>({endpoint: '/trip/add', name: 'Add Trip', initial_state: undefined});
}

export function useUpdateTrip() {
    return usePost<Trip | undefined, Trip>({endpoint: '/trip/update', name: 'Update Trip', initial_state: undefined});
}

export function useDeleteTrip() {
    const send_delete = useDelete({name: 'Delete Trip'});
    return (trip_id: number, on_success: () => void) => send_delete('/trip/' + trip_id, on_success);
}

export function useTripDetails(trip_id?: number | string) {
    return useGet<TripDetails | undefined>({endpoint: '/trip/' + trip_id, name: 'Trip Details', initial_state: undefined});
}

export function useUpdateTripGear() {
    return usePost<undefined, TripGearCategory>({endpoint: '/trip/update_gear', name: 'Trip Gear Update', initial_state: undefined});
}

export function useDeleteTripGear() {
    const send_delete = useDelete({name: 'Delete Trip Gear Category'});
    return (trip_id: number, trip_gear_id: number, on_success: () => void) => send_delete('/trip/' + trip_id + '/' + trip_gear_id, on_success);
}

export function useAddTripGear() {
    return usePost<undefined, AddTripGearCategoryForm>({endpoint: '/trip/add_category', name: 'Add Category', initial_state: undefined});
}

export function useUpdateMealPlan() {
    return usePost<undefined, TripMealGroupEntry>({endpoint: '/trip/meals', name: 'Update Meal', initial_state: undefined});
}

export function useMealGroups() {
    return useGet<MealGroup[] | undefined>({endpoint: '/meal/', name: 'Meal Group', initial_state: undefined});
}

interface AddUserForm {
    username: string;
    password_hash: string;
    display_name: string;
}

export function useAddUser() {
    return usePost<undefined, AddUserForm>({endpoint: '/admin/user', name: 'Add User', initial_state: undefined});
}

export function useUsers() {
    return useGet<User[] | undefined>({endpoint: '/admin/user', name: 'User List', initial_state: undefined});
}

export function useDeleteUser() {
    const send_delete = useDelete({name: 'Delete User'});
    return (user_id: number, on_success: () => void) => send_delete('/admin/user/' + user_id, on_success);
}

/// Gear Category endpoints
interface AddGearCategoryForm {
    name: string;
    description: string;
    parent_id?: number;
    default_desired_qty: number;
}
export function useAddGearCategory() {
    return usePost<undefined, AddGearCategoryForm>({endpoint: '/category/add', name: 'Add Category', initial_state: undefined});
}

export function useUpdateGearCategory() {
    return usePost<undefined, GearCategory>({endpoint: '/category/update', name: 'Update Category', initial_state: undefined});
}

export function useGearCategories() {
    return useGet<GearCategory[] | undefined>({endpoint: '/category/', name: 'Gear Categories', initial_state: undefined});
}

export function useDeleteGearCategory() {
    const send_delete = useDelete({name: 'Delete Gear Category'});
    return (category_id: number, on_success: () => void) => send_delete('/category/' + category_id, on_success);
}

/// Gear endpoints

export function useGear() {
    return useGet<GearItem[] | undefined>({endpoint: '/gear/', name: 'Gear List', initial_state: undefined});
}

interface AddGearItemForm {
    category_id: number;
    make: string;
    model: string;
    description: string;
    weight: number;
}

export function useAddGearItem() {
    return usePost<undefined, AddGearItemForm>({endpoint: '/gear/add', name: 'Add Gear Item', initial_state: undefined});
}

export function useUpdateGearItem() {
    return usePost<undefined, GearItem>({endpoint: '/gear/update', name: 'Update Gear Item', initial_state: undefined});
}

export function useDeleteGearItem() {
    const send_delete = useDelete({name: 'Delete Gear Item'});
    return (gear_item_id: number, on_success: () => void) => send_delete('/gear/' + gear_item_id, on_success);
}

export function useGearItemWeights(gear_id: number) {
    return useGet<GearWeightMeasurement[] | undefined>({endpoint: `/gear/${gear_id}/weights`, name: 'Gear Weights', initial_state: undefined});
}

interface AddGearWeightMeasurementForm {
    gear_id: number;
    weight: number;
}

export function useAddGearWeight() {
    return usePost<undefined, AddGearWeightMeasurementForm>({endpoint: '/gear/add_weight', name: 'Add Gear Weight', initial_state: undefined});
}