import React from 'react';
import {Paper, styled} from "@mui/material";
import Box from "@mui/material/Box";
import {createUseStyles} from "react-jss";

const style = createUseStyles({
    root: {
        overflowX: 'scroll',
    }
})

interface TripViewCardProps {
    flexGrow: number;
    minWidth: number;
    children: any;
}

export function TripViewCard(props: TripViewCardProps) {
    const classes = style();
    const ScrollableBox = styled('div')(({theme}) => ({
        padding: theme.spacing(1),
        minWidth: props.minWidth,
    }));
    return <Box padding={1}
                style={{flexGrow: props.flexGrow}}>
        <Paper elevation={4}
               className={classes.root}>
            <ScrollableBox>
                {props.children}
            </ScrollableBox>
        </Paper>
    </Box>
}