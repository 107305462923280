import React from "react";
import {MenuItem, Select} from "@mui/material";
import {PackCarryType} from "../../api";

interface PackCarrySelectProps {
    value: PackCarryType;
    onChange(value: PackCarryType): void;
    className?: string;
}

export function PackCarrySelect({value, onChange, className, ...rest}: PackCarrySelectProps) {
    const c_name = className !== undefined ? className : "";
    return <Select {...rest}
                   className={c_name}
                   value={value}
                   onChange={(e) => onChange(e.target.value as PackCarryType)}>
            <MenuItem key="pack" value="pack">Pack</MenuItem>
            <MenuItem key="carry" value="carry">Carry</MenuItem>
        </Select>
}
