import React, {useState} from "react";
import {Button, Card, CardActions, CardContent, List, ListItem, TextField} from "@mui/material";
import {createUseStyles} from "react-jss";

const style = createUseStyles({
    body: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error_msg: {
        padding: 8,
    },
    button_box: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
})

interface LoginProps {
    classes?: any;
    error_msg: string;
    login_callback(username: string, password: string): void;
}

export function LoginPage(props: LoginProps) {
    const classes = style();
    const [username, set_username] = useState('');
    const [password, set_password] = useState('');

    const handle_login_button = () => {
        props.login_callback(username, password);
    }

    const handle_keyboard_key = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && username.length > 0 && password.length > 0) {
            handle_login_button();
        }
    }

    return <div className={classes.body}>
        <Card elevation={15}>
            <CardContent className={classes.header}>
                {/*<img alt='logo' height="40px" src="/ASI_Logo.svg" className={classes.asiLogo}/>*/}
                <h1>Packman Login</h1>
            </CardContent>
            <CardContent>
                {props.error_msg.length > 0 &&
                    <div className={classes.error_msg}>
                        {props.error_msg}
                    </div>
                }
                <List>
                    <ListItem>
                        <TextField required
                                   id="username"
                                   label="Username"
                                   onKeyUp={handle_keyboard_key}
                                   onChange={(e) => set_username(e.target.value)}/>
                    </ListItem>
                    <ListItem>
                        <TextField required
                                   id="password"
                                   label="Password"
                                   type="password"
                                   onKeyUp={handle_keyboard_key}
                                   onChange={(e) => set_password(e.target.value)}/>
                    </ListItem>
                </List>
            </CardContent>
            <CardActions className={classes.button_box}>
                <Button id="login_button"
                        variant="contained"
                        disabled={username.length === 0 || password.length === 0}
                        onClick={(e) => handle_login_button()}>
                    Login
                </Button>
            </CardActions>
        </Card>
    </div>
}
