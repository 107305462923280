import React from "react";
import {Paper, Tab, Tabs} from "@mui/material";
import {Link} from "react-router-dom";
import {createUseStyles} from "react-jss";
import {GearList} from "./GearList";
import {GearCategoriesList} from "./GearCategoriesList";

const style = createUseStyles({
    tabs: {
        position: 'relative',
        top: -36,
        left: -25,
        width: '100vw',
    }
})

export function Gear() {
    const classes = style();
    const path_parts = window.location.pathname.split('/').filter(p => p.length !== 0);
    const [selected, setSelected] = React.useState(path_parts.length > 1 ? path_parts[1] : 'gear');

    return <div>
        <Paper elevation={4} className={classes.tabs}>
            <Tabs centered
                  indicatorColor="secondary"
                  textColor="secondary"
                  value={selected}>
                <Tab value="categories" label="Categories" component={Link} to="/gear/categories" onClick={() => setSelected('categories')}/>
                <Tab value="gear" label="Gear" component={Link} to="/gear/gear" onClick={() => setSelected('gear')}/>
            </Tabs>
        </Paper>

        {selected === 'gear' &&
            <GearList/>
        }
        {selected === 'categories' &&
            <GearCategoriesList/>
        }
    </div>
}