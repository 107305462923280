import React, {useMemo, useState} from "react";
import {useAddUser, User} from "../../api";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    TextField
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {createUseStyles} from "react-jss";

const style = createUseStyles({
    fab: {
        position: 'fixed',
        bottom: 25,
        right: 25,
    }
});

interface CreateUserModalProps {
    current_users: User[];
    on_created_user(): void;
}

export function CreateUserModal(props: CreateUserModalProps) {
    const classes = style();
    const create_new_user = useAddUser()[1];
    const [is_open, set_is_open] = useState(false);
    const [username, set_username] = useState('');
    const [display_name, set_display_name] = useState('');
    const [password, set_password] = useState('');

    const current_usernames = useMemo(() => {
        let username_set = new Set<string>();
        for (const user of props.current_users) {
            username_set.add(user.username);
        }
        return username_set;
    }, [props.current_users]);

    const on_close = () => {
        set_is_open(false);
    };

    const is_new_user_valid = username.length !== 0
        && display_name.length !== 0
        && password.length > 8
        && !current_usernames.has(username);
    const on_create_new_user = () => {
        if (is_new_user_valid) {
            create_new_user({
                username: username,
                password_hash: password,
                display_name: display_name
            }, (response) => {
                props.on_created_user();
                on_close();
            });
        }
    }

    return <div>
        <Dialog open={is_open}
                onClose={on_close}>
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add a new user.
                </DialogContentText>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="display_name"
                           label="Display Name"
                           fullWidth
                           variant="standard"
                           value={display_name}
                           onChange={(e) => set_display_name(e.target.value)}/>
                <TextField required
                           margin="dense"
                           id="username"
                           label="Username"
                           fullWidth
                           variant="standard"
                           value={username}
                           onChange={(e) => set_username(e.target.value)}/>
                <TextField required
                           margin="dense"
                           id="password"
                           label="Password"
                           fullWidth
                           variant="standard"
                           type="password"
                           value={password}
                           onChange={(e) => set_password(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={on_close}>Cancel</Button>
                <Button disabled={!is_new_user_valid}
                        onClick={(e) => on_create_new_user()}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        {!is_open &&
            <Fab color="secondary"
                 className={classes.fab}
                 aria-label="add-trip"
                 onClick={() => set_is_open(true)}>
                <AddIcon/>
            </Fab>
        }
    </div>
}