import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab, MenuItem, Select,
    TextField
} from "@mui/material";
import {
    GearCategory,
    GearItem,
    useAddGearItem,
} from "../../api";
import {createUseStyles} from "react-jss";
import AddIcon from "@mui/icons-material/Add";

const style = createUseStyles({
    fab: {
        position: 'fixed',
        bottom: 25,
        right: 25,
    }
});

interface AddGearItemModalProps {
    existing_categories: Record<number, GearCategory>;
    onChange(item: GearItem): void;
    onClose(): void;
}

const default_item: GearItem = {
    id: -1,
    make: '',
    model: '',
    category_id: -1,
    description: '',
    date_added: 0,
};

export function AddGearItemModal(props: AddGearItemModalProps) {
    const classes = style();
    const add_gear_item = useAddGearItem()[1];
    // const set_gear_weight = useAddGearWeight()[1];
    const [is_open, set_is_open] = useState(false);
    const [item, set_item] = useState<GearItem>(default_item);
    const [item_weight, set_item_weight] = useState(0.0);

    const on_close = () => {
        set_is_open(false);
        props.onClose();
        set_item(default_item);
        set_item_weight(0);
    };

    const on_confirm_item = () => {
        add_gear_item({
            make: item.make,
            model: item.model,
            description: item.description,
            category_id: item.category_id,
            weight: item_weight,
        }, () => {
            props.onChange(item);
            on_close();
        });
    }

    const is_item_valid = item.make.length > 0
        && item.model.length > 0
        && item.description.length > 0
        && item.category_id >= 0
        && item_weight > 0;

    return <div>
        <Dialog open={is_open}
                onClose={on_close}>
            <DialogTitle>Add Gear Item</DialogTitle>
            <DialogContent>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="make"
                           label="Make"
                           fullWidth
                           variant="standard"
                           value={item.make}
                           onChange={(e) => set_item({...item, make: e.target.value})}/>
                <TextField autoFocus
                           required
                           margin="dense"
                           id="model"
                           label="Model"
                           fullWidth
                           variant="standard"
                           value={item.model}
                           onChange={(e) => set_item({...item, model: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="description"
                           label="Description"
                           multiline
                           fullWidth
                           variant="standard"
                           value={item.description}
                           onChange={(e) => set_item({...item, description: e.target.value})}/>
                <TextField required
                           margin="dense"
                           id="weight"
                           label="Weight"
                           fullWidth
                           variant="standard"
                           type="number"
                           value={item_weight}
                           onChange={(e) => set_item_weight(Number(e.target.value))}/>
                <Select value={item.category_id}
                        onChange={(e) => set_item({...item, category_id: Number(e.target.value)})}
                        fullWidth>
                    <MenuItem value={-1}>Select Category</MenuItem>
                    {Object.values(props.existing_categories).map((category, i) => (
                        <MenuItem key={i} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={on_close}>Cancel</Button>
                <Button disabled={!is_item_valid}
                        onClick={() => on_confirm_item()}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>

        {!is_open &&
            <Fab color="secondary"
                 className={classes.fab}
                 aria-label="add-gear-category"
                 onClick={() => {
                     set_item(default_item);
                     set_is_open(true);
                 }}>
                <AddIcon/>
            </Fab>
        }
    </div>
}