import React, {useState} from "react";
import {
    GearCategory, GearItem, GearWeightMeasurement, PackCarryType,
    TripDetails,
    TripGearCategory, useDeleteTripGear,
    useUpdateTripGear
} from "../../api";
import {
    Checkbox, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {createUseStyles} from "react-jss";
import {PackCarrySelect} from "../common/PackCarrySelect";
import {ConfirmDialog} from "../common/ConfirmDialog";
import {TripViewCard} from "./TripViewCard";

const style = createUseStyles({
    root: {
        flexGrow: 3,
        overflowX: 'scroll',
    },
    card_root: {
        // minWidth: 650
    },
    gear_select: {
        width: 250,
    },
    pack_carry_select: {
        width: 100,
    }
});


interface TripGearListProps {
    trip_details: TripDetails;
    gear_categories: Record<number, GearCategory>;
    gear: Record<number, GearItem>;
    gear_weights: Record<number, GearWeightMeasurement>;
    on_needs_refresh(): void;
}

export function TripGearList(props: TripGearListProps) {
    const classes = style();
    const update_trip_gear = useUpdateTripGear()[1];
    const delete_trip_gear = useDeleteTripGear();
    const [trip_category_to_delete, set_trip_category_to_delete] = useState<TripGearCategory | undefined>(undefined);
    const [is_busy_deleting, set_is_busy_deleting] = useState(false);

    const get_trip_category_name = (category: TripGearCategory | undefined): string => {
        if (category !== undefined) {
            if (props.gear_categories.hasOwnProperty(category.gear_category_id)) {
                return props.gear_categories[category.gear_category_id].name;
            } else {
                return "unknown";
            }
        }
        return "";
    };

    const toggle_packed = (category: TripGearCategory) => {
        category.is_packed = !category.is_packed;
        update_trip_gear(category, () => {});
        props.on_needs_refresh();
    }

    const set_category_item = (category: TripGearCategory, gear_id: number) => {
        category.gear_id = gear_id;
        update_trip_gear(category, () => {});
        props.on_needs_refresh();
    }

    const set_category_pack_carry_category = (category: TripGearCategory, pack_carry: PackCarryType) => {
        category.weight_category = pack_carry;
        update_trip_gear(category, () => {});
        // forceUpdate();
        props.on_needs_refresh();
    };

    const make_gear_select_option = (trip_category: TripGearCategory) => {
        const gear_options = Object.values(props.gear).filter((item) => (
            item.category_id === trip_category.gear_category_id
        ));
        return <Select value={trip_category.gear_id ? trip_category.gear_id : undefined}
                       className={classes.gear_select}
                       onChange={(e) => set_category_item(trip_category, e.target.value as number)}>
                <MenuItem value={undefined}>None</MenuItem>
            {gear_options.map((item, i) => (
                <MenuItem key={i} value={item.id}>{item.make} - {item.model}</MenuItem>
            ))}
        </Select>
    }

    const make_pack_carry_select_option = (trip_category: TripGearCategory) => {
        return <PackCarrySelect value={trip_category.weight_category}
                                onChange={(cat) => set_category_pack_carry_category(trip_category, cat)}
                                className={classes.pack_carry_select}/>
    };

    const delete_trip_gear_category = (category: TripGearCategory | undefined) => {
        if (category !== undefined) {
            set_is_busy_deleting(true);
            delete_trip_gear(props.trip_details.trip.id, category.id, () => {
                set_is_busy_deleting(false);
                set_trip_category_to_delete(undefined);
                props.on_needs_refresh();
            });
        }
    }

    return <TripViewCard flexGrow={3} minWidth={650}>
        {/* Something to help filter/sort this would be nice. Dataview maybe? */}
        <List>
            {props.trip_details.gear.map((category, i) => {
                const gear_category = props.gear_categories[category.gear_category_id];
                let secondary_text: any = gear_category.description;
                if (category.gear_id && props.gear_weights.hasOwnProperty(category.gear_id)) {
                    const item = props.gear_weights[category.gear_id];
                    secondary_text = <span>{item.weight} oz</span>
                }
                const actions = <div>
                    {make_pack_carry_select_option(category)}
                    {make_gear_select_option(category)}
                    <IconButton onClick={() => set_trip_category_to_delete(category)}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
                return <ListItem key={i}
                                 secondaryAction={actions}>
                    <ListItemButton>
                        <ListItemIcon onClick={() => toggle_packed(category)}>
                            <Checkbox edge="start"
                                      checked={category.is_packed && category.gear_id !== undefined}/>
                        </ListItemIcon>
                        <ListItemText primary={gear_category.name}
                                      secondary={secondary_text}/>
                    </ListItemButton>
                </ListItem>
            })}
        </List>
        <ConfirmDialog is_open={trip_category_to_delete !== undefined}
                       title={"Delete \"" + get_trip_category_name(trip_category_to_delete) + "\"?"}
                       content={"Are you sure you want to delete \"" + get_trip_category_name(trip_category_to_delete) + "\"?"}
                       onClose={() => set_trip_category_to_delete(undefined)}
                       onConfirmed={() => delete_trip_gear_category(trip_category_to_delete)}
                       is_busy={is_busy_deleting}/>
    </TripViewCard>
}