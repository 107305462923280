import React from 'react';
import {Navbar} from "./components/nav_bar/NavBar";
import AppBar from "@mui/material/AppBar";
import {Container} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {TripList} from "./components/trip_list/TripList";
import {TripView} from "./components/trip_view/TripView";
import {Administration} from "./components/administration/Administration";
import {useUserInfo} from "./api";
import {Gear} from "./components/gear/Gear";

export default function App() {
    const {user} = useUserInfo();
    return (
        <div>
            <Navbar/>
            <AppBar/>
            <Container style={{marginTop: 100, maxWidth: '100%'}}>
                <Routes>
                    <Route path="/" element={<TripList/>}/>
                    <Route path="/trips" element={<TripList/>}/>
                    <Route path="/trips/:trip_id" element={<TripView/>}/>
                    <Route path="/gear" element={<Gear/>}/>
                    <Route path="/gear/categories" element={<Gear/>}/>
                    <Route path="/gear/gear" element={<Gear/>}/>
                    {user.is_admin &&
                        <Route path="/admin" element={<Administration/>}/>
                    }
                </Routes>
            </Container>
        </div>
    );
}
