import { createTheme } from "@mui/material";
import {deepPurple, orange} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        // mode: "dark",
        primary: orange,
        secondary: deepPurple,
    }
});

export default theme;
